<template>
    <div class="row g-0">
      <img src="../assets/main.png" class="image-left"/>
      <div class="main-text">
        <h2>
          Bens Fuels has 10 years in the fuel supply and distribution business. 
          We are a small family run business based in Buckinghamshire. 
          The team is made up of 2 members and our wonderful drivers.
          <div style="height:10px" />
            Ben Patton- Company Director <br>
            Sophia Patton- Administration/Office Manager 
        </h2>
      </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>

<style scoped>
.image-left
{
  width:62%;
  height: auto;
  float:left;
}

.main-text
{
  padding: 20px;
  padding-top:75px;
  padding-bottom:75px;
  width: 38%;
  background-color: #335ba3;
  float: right;
}

@media not all and (min-resolution:.001dpcm) { 
    .image-left
    {
      width:62%;
      height:100%;
      float:left;
    }
}

@media screen and (max-width: 850px) {
  .image-left
  {
    width:100%;
    height: 100%;
  }

  .main-text
  {
    padding: 20px;
    padding-top:25px;
    width: 100%;
    background-color: #335ba3;
  }
}

@media screen and (max-width: 1200px) and (min-width: 850px) {
  .image-left
  {
    width:60%;
    height:100%;
    float:left;
  }

  .main-text
  {
    padding: 10px;
    padding-top:25px;
    background-color: #335ba3;
    font-size: 12pt;
    width: 40%;
    float: right;
  }
}

h1
{
  color: black;
  font-weight: 650;
  text-align: center;
  font-size: 5.5em;
}

h2
{
  color: white;
  font-weight: 350;
  font-size: 1.15em;
  line-height: 32px;
}
</style>